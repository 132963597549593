import { SearchParams } from '@/types/api';

import { getURLSearchParamsWithArray } from './getURLSearchParamsWithArray';

export function applyArraySearchParamsToURI(
  baseUri: string,
  searchParams: SearchParams
) {
  const params = getURLSearchParamsWithArray(searchParams);
  const strParams = params.toString();
  return baseUri + (strParams.length ? '?' + strParams : '');
}
