/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ModelFilteredResponseDto = {
    id: string;
    name: string;
    type: ModelFilteredResponseDto.type;
    projectId: string;
    creationTime: string;
    ownerName?: string;
    editable?: boolean;
};

export namespace ModelFilteredResponseDto {

    export enum type {
        SHOWCASE = 'SHOWCASE',
        DICTIONARY = 'DICTIONARY',
        VIEW = 'VIEW',
        EXTENDED_VIEW = 'EXTENDED_VIEW',
        KAFKA = 'KAFKA',
        POSTGRES = 'POSTGRES',
        CLICKHOUSE = 'CLICKHOUSE',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
    }


}

