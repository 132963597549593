<template>
  <DefaultWithHeader v-bind="$props">
    <template #sidebar-left>
      <q-drawer
        class="page-common-overlay mks-shadow-2 relative-position"
        show-if-above
        :width="84"
      >
        <Sidebar :project-id="projectId" />
      </q-drawer>
    </template>
  </DefaultWithHeader>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue';
import { useRoute } from 'vue-router';

import { Sidebar } from '@components/Sidebar';

import DefaultWithHeader from './DefaultWithHeader.vue';

interface LayoutProps {
  container?: boolean;
  contentClass?: string;
}

withDefaults(defineProps<LayoutProps>(), {
  container: false,
  contentClass:
    'page-common-padding-area page-common-padding-area_with-sidebar',
});

const route = useRoute();
const projectId = computed(() => {
  return Array.isArray(route.params.projectId)
    ? route.params.projectId?.[0] || ''
    : route.params.projectId;
});
</script>
<style scoped lang="scss">
:deep(.q-drawer) {
  padding: rem(16px) 0 rem(16px) rem(8px);
  background: transparent;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(12px);
    height: 100%;
    content: '';
    background-color: var(--cv-surface);
  }
}
</style>
