/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TableSchemaColumnDto } from './TableSchemaColumnDto';

export type TableSchemaDto = {
    id?: string;
    name?: string;
    orderBy?: Array<string>;
    columns?: Array<TableSchemaColumnDto>;
    primaryKeyColumn?: string;
    modelType?: TableSchemaDto.modelType;
    model_id?: string;
    table_name?: string;
};

export namespace TableSchemaDto {

    export enum modelType {
        SHOWCASE = 'SHOWCASE',
        DICTIONARY = 'DICTIONARY',
        VIEW = 'VIEW',
        EXTENDED_VIEW = 'EXTENDED_VIEW',
        KAFKA = 'KAFKA',
        POSTGRES = 'POSTGRES',
        CLICKHOUSE = 'CLICKHOUSE',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
    }


}

