<template>
  <nav class="breadcrumbs">
    <ol class="breadcrumbs__list row items-center no-wrap">
      <li
        v-for="(step, idx) in loadedSteps"
        :class="[
          'breadcrumbs__item breadcrumb row items-center no-wrap',
          step.isStatic
            ? 'breadcrumbs__item_static'
            : 'breadcrumbs__item_dynamic',
        ]"
        :key="idx"
      >
        <component
          v-if="step.isLoaded"
          :is="!step.disabled ? 'router-link' : 'span'"
          :class="[
            'breadcrumb__link row items-center no-wrap',
            { breadcrumb__link_disabled: step.disabled },
          ]"
          :to="!step.disabled ? step.route : undefined"
        >
          <IconNew
            v-if="step.display.icon"
            :value="step.display.icon.name"
            :tooltip="step.display.text"
            class="breadcrumb__icon"
          />
          <span v-if="step.display.text" class="breadcrumb__text">
            {{ step.display.text }}
          </span>
        </component>
        <BiSpinner v-else class="breadcrumb__loader" color="primary" />
      </li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import isEqual from 'lodash.isequal';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useBreadcrumbsWatch } from '@/composables/query';
import type { CrumbsHierarchy } from '@/types/breadcrumb';
import type { RelationType } from '@/types/elementRelations';
import { clone } from '@clearview/helpers';
import { Spinner as BiSpinner, IconNew } from '@clearview/ui';

import { useQuery } from './composables';
import { pages, structure } from './structure';
import type {
  Breadcrumb,
  CrumbStructure,
  DynamicCrumbInfo,
  DynamicCrumbStructure,
} from './types';

const route = useRoute();

const {
  getDynamicCrumbsFromServer,
  getLastHierarchy,
  findHierarchy,
  saveFullHierarchy,
  fetchHierarchy,
} = useQuery();

const isCrumbsExpected = ref(false);
const viewDynamicIcons = ref(false);
const steps = ref<Breadcrumb[]>([]);
const crumbHierarchy = ref<CrumbsHierarchy[]>([]);
const loadedItemIndex = ref<number | null>(null);

const loadedSteps = computed<Breadcrumb[]>(() => {
  const visibleFiltered = steps.value.filter(item => !item.invisible);

  const sIndex = loadedItemIndex.value;
  if (sIndex === null) return visibleFiltered;

  return visibleFiltered.filter((_, i) => i <= sIndex);
});

const crumbsExpected = () => {
  return pages.includes(route.name as string);
};

const lastCrumb = computed(() => {
  const lastIndex = structure.findLastIndex(
    el =>
      el.route.name === route.name &&
      (el.route.query ? isEqual(el.route.query, route.query) : true)
  );

  if (structure[lastIndex]?.invisible) {
    const newIndex = structure.findLastIndex(
      (el, i) => i < lastIndex && !el.invisible
    );
    return structure[newIndex];
  }

  return structure[lastIndex];
});

const lastDynCrumb = computed(() => {
  return structure.findLast(
    el =>
      !el.info.isStatic &&
      el.route.name === route.name &&
      (el.route.query ? isEqual(el.route.query, route.query) : true)
  ) as DynamicCrumbStructure | undefined;
});

const getCrumbsStructure = () => {
  const reversedCrumbs: CrumbStructure[] = [];
  let lCrumb = clone(lastCrumb.value);
  while (lCrumb) {
    reversedCrumbs.push(lCrumb);
    const prevCrumb = structure.find(el => el.id === lCrumb?.parentId);
    if (!prevCrumb) {
      break;
    }
    lCrumb = prevCrumb;
  }
  return reversedCrumbs.reverse();
};

const isDisabledCrumb = (crumbStructure: CrumbStructure) => {
  if (!lastCrumb.value) return false;
  if (crumbStructure.id === lastCrumb.value.id) return true;
  if (crumbStructure.disableRelation === undefined) return false;
  return crumbStructure.disableRelation.includes(lastCrumb.value.id);
};

const applyHierarchyInfo = (item: Breadcrumb, h: CrumbsHierarchy) => {
  item.route.params = {
    [(item.info as DynamicCrumbInfo).paramName]: h.id,
  };
  item.display = {
    text: h.name,
    icon: viewDynamicIcons.value ? h.visualOptions.icon : null,
  };
  item.isLoaded = true;
};

const getCrumbsByStructure = (crumbsStructure: CrumbStructure[]) => {
  const crumbs: Breadcrumb[] = [];
  const mapEntity: { [key in RelationType]?: CrumbsHierarchy } = {};

  crumbsStructure.reverse().forEach((crumbStructure, i) => {
    const info = crumbStructure.info;

    if (!info.isStatic) {
      const crumb: Breadcrumb = {
        id: crumbStructure.id,
        isStatic: false,
        info,
        route: {
          name: crumbStructure.route.name,
          query: crumbStructure.route.query,
        },
        display: {},
        disabled: isDisabledCrumb(crumbStructure),
        isLoaded: false,
        invisible: crumbStructure.invisible,
      };

      let findedHierarchy: CrumbsHierarchy[] | undefined;

      // берём значение из карты от ранее сохранённых дочерних элементов из-за реверса
      // это нужно для нахождения id которого нет в роуте
      const currVal = mapEntity[info.entityType];
      if (currVal) {
        applyHierarchyInfo(crumb, currVal);
      }
      // берём id из роута и ищем в query сторе
      else {
        const id = route.params[info.paramName] as string | undefined;

        if (id) {
          findedHierarchy = findHierarchy(info.entityType, id);
          if (findedHierarchy) {
            const item = findedHierarchy.find(h => h.type === info.entityType);
            if (item) {
              applyHierarchyInfo(crumb, item);
            }

            findedHierarchy.forEach(h => {
              mapEntity[h.type] = h;
            });
          }
        }
      }

      // для случаев когда у нас нет id из роута, а дочерние крошки ещё не загружены для проверки корня
      if (
        !findedHierarchy &&
        info.entityType !== lastDynCrumb.value?.info.entityType
      ) {
        const lastData = getLastHierarchy();
        if (lastData) {
          const item = lastData.find(h => h.type === info.entityType);
          if (item) {
            findedHierarchy = lastData;
            applyHierarchyInfo(crumb, item);
          }
        }
      }

      const reversedIndex = crumbsStructure.length - i - 1;

      if (!crumb.isLoaded) {
        if (loadedItemIndex.value === null) {
          loadedItemIndex.value = i;
          loadedItemIndex.value = reversedIndex;
        } else if (loadedItemIndex.value > reversedIndex) {
          loadedItemIndex.value = reversedIndex;
        }
      }

      crumbs.push(crumb);
    } else {
      const iconName = info.iconName;
      const icon = iconName ? { name: iconName, color: null } : undefined;
      const crumb: Breadcrumb = {
        id: crumbStructure.id,
        isStatic: true,
        info,
        route: crumbStructure.route,
        display: { text: info.text, icon },
        disabled: isDisabledCrumb(crumbStructure),
        isLoaded: true,
        invisible: crumbStructure.invisible,
      };
      crumbs.push(crumb);
    }
  });
  return crumbs.reverse();
};

const generateCrumbs = () => {
  isCrumbsExpected.value = crumbsExpected();
  if (!isCrumbsExpected.value) return;
  const crumbsStructure = getCrumbsStructure();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  getDynamicCrumbsFromServer(crumbsStructure).then(res => {
    crumbHierarchy.value = res;
  });
  steps.value = getCrumbsByStructure(crumbsStructure);
};

watch(
  lastCrumb,
  () => {
    generateCrumbs();
  },
  { immediate: true }
);

const updateStepsFromCache = () => {
  const notFoundArr: string[] = [];

  steps.value = steps.value.map(oldStep => {
    if (oldStep.isStatic) return oldStep;
    const info = oldStep.info as DynamicCrumbInfo;
    const type = info.entityType;

    const id = oldStep.route?.params?.[info.paramName] as string | undefined;
    if (!id) return oldStep;

    const findedHierarchy = findHierarchy(type, id);

    if (findedHierarchy) {
      const newStep = clone(oldStep);
      const item = findedHierarchy.find(h => h.type === type);
      if (item) {
        applyHierarchyInfo(newStep, item);
        return newStep;
      }
    } else {
      notFoundArr.push(id);
    }
    return oldStep;
  });
  return notFoundArr;
};

const updateCrumbs = async () => {
  const notFoundArr: string[] = updateStepsFromCache();

  if (notFoundArr.length) {
    const item = steps.value.find(step => step.id === lastDynCrumb.value?.id);
    if (item) {
      const id = item.route.params?.[
        (item.info as DynamicCrumbInfo).paramName
      ] as string | undefined;
      if (id) {
        const hierarchy = await fetchHierarchy(id);
        saveFullHierarchy(hierarchy);
        updateStepsFromCache();
      }
    }
  }
};

useBreadcrumbsWatch(async () => {
  await updateCrumbs();
});

watch(crumbHierarchy, newArr => {
  newArr.forEach(h => {
    const item = steps.value.find(
      s => (s.info as DynamicCrumbInfo).entityType === h.type
    );
    if (item) {
      applyHierarchyInfo(item, h);
    }
  });
  loadedItemIndex.value = null;
});
</script>

<style scoped lang="scss">
@import 'Breadcrumbs';
</style>
src/bi-views/views/components/Breadcrumbs/structure
