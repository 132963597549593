/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdditionalFilter } from './AdditionalFilter';
import type { ColumnDto } from './ColumnDto';
import type { ConditionalFormat } from './ConditionalFormat';
import type { DynamicDto } from './DynamicDto';
import type { Filter } from './Filter';
import type { HeaderColumn } from './HeaderColumn';
import type { SelectRestriction } from './SelectRestriction';
import type { SortColumn } from './SortColumn';

export type ExportFileStructure = {
    modelId?: string;
    widgetId?: string;
    isDetailed?: boolean;
    columns?: Array<ColumnDto>;
    selectRestriction?: SelectRestriction;
    filters?: Array<Filter>;
    additionalFilters?: Array<AdditionalFilter>;
    conditionalFormats?: Array<ConditionalFormat>;
    columnsLevels?: Array<Array<string>>;
    dynamics?: Array<DynamicDto>;
    level?: number;
    sort?: Array<SortColumn>;
    limit?: number;
    page?: number;
    widgetName: string;
    headerColumns: Array<HeaderColumn>;
    fileType: ExportFileStructure.fileType;
};

export namespace ExportFileStructure {

    export enum fileType {
        XLSX = 'XLSX',
        CSV = 'CSV',
    }


}

