import { loadModules } from '@clearview/helpers';

let loadModulesApiServices = () => {
  console.log('loadModulesApiServices plug :)');
};

try {
  loadModulesApiServices = () => {
    const modules = import.meta.glob('./*.ts', { eager: true });
    return loadModules(modules);
  };
} catch (error) {
  console.log(error);
}

const apiServicesImported = loadModulesApiServices();

export default apiServicesImported;
