import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '@/bi-core/constants/device';
import { EDashboardDevice } from '@/types/dashboard';

export const useDevice = defineStore('device', () => {
  const device = ref<EDashboardDevice>();

  const setDevice = (isMobile: boolean, width: number) => {
    if (!isMobile) {
      device.value =
        width < MOBILE_BREAKPOINT
          ? EDashboardDevice.Mobile
          : width < TABLET_BREAKPOINT
          ? EDashboardDevice.Tablet
          : EDashboardDevice.Desktop;
    } else
      device.value =
        width > MOBILE_BREAKPOINT
          ? EDashboardDevice.Tablet
          : EDashboardDevice.Mobile;
  };

  return {
    device,
    setDevice,
  };
});
