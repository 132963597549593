import { IEntityBaseApiServiceParams } from '@/api/client';
import { applyArraySearchParamsToURI } from '@/bi-core/helpers/api/applyArraySearchParamsToURI';
import { IApiRequestParams } from '@/types/api';
import getConstants from '@bi-core/constants/env';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
  RequestMethod,
} from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'ConnectionApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class ConnectionApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  check<TResponse>(data: unknown, options?: IRestOptions) {
    const uri = '/check';
    return this.sendRequest<TResponse>(
      uri,
      null,
      { body: JSON.stringify(data), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }

  fetchDse<TResponse>(data: unknown, options?: IRestOptions) {
    const uri = '/dse';
    return this.sendRequest<TResponse>(
      uri,
      null,
      { body: JSON.stringify(data), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }

  fetchAllByFilters<TResponse>(
    meta: Omit<IApiRequestParams, 'filter'> & { projectId: string },
    filter: Record<string, unknown> = {},
    options?: IRestOptions
  ) {
    const uri = applyArraySearchParamsToURI('/filter', meta);

    return this.sendRequest<TResponse>(
      uri,
      null,
      { body: JSON.stringify(filter), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }
}
