import { IEntityBaseApiServiceParams } from '@/api/client';
import { applyArraySearchParamsToURI } from '@/bi-core/helpers/api/applyArraySearchParamsToURI';
import type { SearchParams } from '@/types/api';
import type {
  ElementRelationExistence,
  ElementRelationFilter,
} from '@/types/elementRelations';
import getConstants from '@bi-core/constants/env';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
  RequestMethod,
} from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'ElementRelationsApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class ElementRelationsApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  existence(
    data: ElementRelationExistence,
    params: SearchParams = {},
    options?: IRestOptions
  ) {
    return this.sendRequest(
      '/existence',
      params,
      {
        body: JSON.stringify(data),
        method: RequestMethod.POST,
      },
      { method: RequestMethod.POST, ...options }
    );
  }

  filter(
    params: SearchParams = {},
    data: ElementRelationFilter,
    options?: IRestOptions
  ) {
    const uri = applyArraySearchParamsToURI('/filter', params);
    return this.sendRequest(
      uri,
      null,
      {
        body: JSON.stringify(data),
        method: RequestMethod.POST,
      },
      { method: RequestMethod.POST, ...options }
    );
  }
}
