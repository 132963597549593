import { IEntityBaseApiServiceParams } from '@/api/client';
import { applyArraySearchParamsToURI } from '@/bi-core/helpers/api/applyArraySearchParamsToURI';
import { IApiRequestParams } from '@/types/api';
import type { IDashboardData } from '@/types/dashboard';
import getConstants from '@bi-core/constants/env';
// ВАЖНО! Импорт делать именно из /helpers: будет ошибка импорта
import { getDefaultPage } from '@components/Dashboard/helpers';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
  RequestMethod,
} from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'DashboardApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class DashboardApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  async add<TResponse>(data: Partial<IDashboardData>, options?: IRestOptions) {
    const page = getDefaultPage();
    data = {
      grid: {
        items: [],
        pages: [page],
      },
      ...data,
    };
    return await this.sendRequest<TResponse>(
      this.pathAdd,
      null,
      { body: JSON.stringify(data), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }

  async copy<TResponse>(data: Partial<IDashboardData>, options?: IRestOptions) {
    return await this.sendRequest<TResponse>(
      '/copy',
      null,
      { body: JSON.stringify(data), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }

  fetchAllByFilters<TResponse>(
    meta: Omit<IApiRequestParams, 'filter'> & { projectId: string },
    filter: Record<string, unknown> = {},
    options?: IRestOptions
  ) {
    const uri = applyArraySearchParamsToURI('/filter', meta);

    return this.sendRequest<TResponse>(
      uri,
      null,
      { body: JSON.stringify(filter), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }

  fetchExtended<TResponse>(
    meta: Omit<IApiRequestParams, 'filter'>,
    filter: Record<string, unknown> = {},
    options?: IRestOptions
  ) {
    const uri = applyArraySearchParamsToURI('/filter/extended', meta);

    return this.sendRequest<TResponse>(
      uri,
      null,
      { body: JSON.stringify(filter), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }

  fetchSchemas<TResponse>(id: string, options?: IRestOptions) {
    return this.sendRequest<TResponse>(
      `/${id}/schemas`,
      null,
      {},
      { ...options }
    );
  }
}
