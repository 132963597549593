import { useAuthStore } from '@/store/bi-store/auth';
import type { IRouterContext } from '@/types/vue-router';

export function guest({ next }: IRouterContext): void {
  const authStore = useAuthStore();

  authStore.isPublicRoute = true;

  return next();
}
