/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConditionalFormatField = {
    modelId?: string;
    formula?: string;
    aggregateFunction?: ConditionalFormatField.aggregateFunction;
};

export namespace ConditionalFormatField {

    export enum aggregateFunction {
        SUM = 'SUM',
        AVG = 'AVG',
        COUNT = 'COUNT',
        UNIQ = 'UNIQ',
        MIN = 'MIN',
        MAX = 'MAX',
        NONE = 'NONE',
        AUTO = 'AUTO',
        MEDIAN = 'MEDIAN',
    }


}

