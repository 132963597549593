import { loadModules } from '@clearview/helpers';

let loadModulesApiServices = () => {
  console.log('loadModulesApiServices plug :)');
};

try {
  loadModulesApiServices = () => {
    const modules = import.meta.glob('./**.ts', { eager: true });
    const overrideImportedAbsolutePath = loadModules(modules);

    return Object.keys(overrideImportedAbsolutePath)
      .map(file => ({ path: file, name: file.split('/').pop() }))
      .reduce((modules, file) => {
        modules[file.name] = overrideImportedAbsolutePath[file.path];
        return modules;
      }, {});
  };
} catch (error) {
  //
}

const modulesApiServicesImported = loadModulesApiServices();
export default modulesApiServicesImported;
