/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ViewJoinConditionDto } from './ViewJoinConditionDto';
import type { ViewSourceTableDto } from './ViewSourceTableDto';

export type ViewJoinDataDto = {
    joinAlias?: string;
    joinedTable?: ViewSourceTableDto;
    joinType?: ViewJoinDataDto.joinType;
    joinConditions?: Array<ViewJoinConditionDto>;
};

export namespace ViewJoinDataDto {

    export enum joinType {
        LEFT = 'LEFT',
        RIGHT = 'RIGHT',
        INNER = 'INNER',
        OUTER = 'OUTER',
    }


}

