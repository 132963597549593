import apiMiddlewares from '@/api/client/middlewares';
import { useBreadcrumbStore } from '@/store/bi-store/breadcrumb';
import type { CrumbsHierarchy } from '@/types/breadcrumb';
import type { RelationType } from '@/types/elementRelations';
import type { TQueryKey } from '@/types/query';
import { clone } from '@clearview/helpers';
import { useQueryClient } from '@tanstack/vue-query';

export function useBreadcrumbs() {
  const queryNamespace = 'hierarchy';
  const lastFetchedKey = [queryNamespace, 'last-fetched'];
  const queryClient = useQueryClient();
  const crumbsStore = useBreadcrumbStore();

  const getQueryKey = (type: RelationType, id: string): TQueryKey => [
    queryNamespace,
    type,
    id,
  ];

  const getArrayHierarchy = (crumbsHierarchy: CrumbsHierarchy) => {
    const nodes: CrumbsHierarchy[] = [];
    let currentNode: CrumbsHierarchy | null = clone(crumbsHierarchy);
    while (currentNode) {
      nodes.push(currentNode);
      currentNode = clone(currentNode.children);
    }
    return nodes;
  };

  const fetchHierarchy = async (lastDynCrumbId: string) => {
    const hierarchyApi = apiMiddlewares.hierarchy();
    const result = await hierarchyApi.get({
      id: lastDynCrumbId,
    });

    const crumbsHierarchy = result.response as CrumbsHierarchy;

    return getArrayHierarchy(crumbsHierarchy);
  };

  const findHierarchy = (type: RelationType, id: string) => {
    const key = getQueryKey(type, id);
    return queryClient.getQueryData<CrumbsHierarchy[]>(key);
  };

  const getLastHierarchy = () => {
    return queryClient.getQueryData<CrumbsHierarchy[]>(lastFetchedKey);
  };

  const saveEntityHierarchy = (
    type: RelationType,
    id: string,
    hierarchy: CrumbsHierarchy[]
  ) => {
    queryClient.setQueryData(getQueryKey(type, id), hierarchy);
  };

  const saveFullHierarchy = (hierarchy: CrumbsHierarchy[]) => {
    hierarchy.forEach((h, i) => {
      if (hierarchy) {
        saveEntityHierarchy(h.type, h.id, hierarchy.slice(0, i + 1));
      }
    });
  };

  const saveLastHierarchy = (hierarchy: CrumbsHierarchy[]) => {
    queryClient.setQueryData(lastFetchedKey, hierarchy);
  };

  const refreshBreadcrumbs = () => {
    crumbsStore.needRefresh = true;
  };

  const updateBreadcrumbsName = (
    entityType: RelationType,
    id: string,
    name: string
  ) => {
    const data = findHierarchy(entityType, id);

    if (data) {
      const item = data.find(row => row.type === entityType);
      if (item) {
        item.name = name;
        saveLastHierarchy(data);
      }
    }

    const lastData = getLastHierarchy();
    if (lastData) {
      const item = lastData.find(
        row => row.type === entityType && row.id === id
      );
      if (item) {
        item.name = name;
        saveLastHierarchy(lastData);
      }
    }

    refreshBreadcrumbs();
  };

  return {
    getQueryKey,
    saveEntityHierarchy,
    saveFullHierarchy,
    saveLastHierarchy,
    getLastHierarchy,
    updateBreadcrumbsName,
    findHierarchy,
    fetchHierarchy,
    refreshBreadcrumbs,
  };
}
