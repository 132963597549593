/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { JoiningColumn } from './JoiningColumn';

export type ModelJoin = {
    sourceModelId: string;
    targetModelId: string;
    joinType: ModelJoin.joinType;
    columns: Array<JoiningColumn>;
};

export namespace ModelJoin {

    export enum joinType {
        LEFT = 'LEFT',
        RIGHT = 'RIGHT',
        INNER = 'INNER',
        FULL = 'FULL',
    }


}

