import type { GridStackOptions } from 'cv-gridstack';

import type {
  DashboardFilterRequestDto,
  DashboardModelSchemaColumnDto,
  DashboardModelSchemaDto,
  DashboardResponseDto,
  Page,
} from '@/api/generated';
import { Item } from '@/api/generated';
import type { Action, IVisualOptions, ValueOf } from '@/types/common';
import type { DynamicAdditionalFilter } from '@/types/filter';
import { EDataType, ETypeColumn } from '@/types/model';
import {
  TFormattedText,
  TextEditorChangeData,
  TextVerticalAlignment,
} from '@/types/textEditor';
import { WidgetLocalSettings } from '@/types/widget';
import type { DashboardElementSettings } from '@components/DashboardElements/types';
import type { Condition } from '@components/Filter/types';

export const enum EActionNames {
  // Filter = 'filter',
  // FilterClear = 'filter-clear',
  Save = 'save',
  Copy = 'copy',
  NotSave = 'notSave',
  SaveBack = 'saveBack',
  Fetch = 'fetch',
  ModeView = 'modeView',
  Add = 'add',
  Back = 'back',
  Export = 'export',
  Right = 'right',
  Undo = 'undo',
  Redo = 'redo',
  DeleteList = 'deleteList',
  NotDeleteList = 'notDeleteList',
  UpdateTitle = 'updateTitle',
  FullScreen = 'fullScreen',
  AddShareDashboard = 'addShareDashboard',
  RemoveShareDashboard = 'removeShareDashboard',
  CopyPublicDashboardLink = 'copyPublicDashboardLink',
  AddButton = 'addButton',
  AddFilter = 'addFilter',
  MobileMode = 'mobileMode',
  DesktopMode = 'desktopMode',
  TabletMode = 'tabletMode',
}

export const enum EDashboardActions {
  ChangeFreeMovement = 'changeFreeMovement',
  Preview = 'preview',
  OpenPanelTable = 'openPanelTable',
  CopyPanel = 'copyPanel',
  CloneElement = 'cloneELement',
  DeletePanel = 'deletePanel',
  GetUp = 'getUp',
  GetDown = 'getDown',
  ChangePage = 'changePage',
  Update = 'update',
  Delete = 'delete',
}

export const enum EStateDashboard {
  View = 'view',
  Edit = 'edit',
  Add = 'add',
  Filter = 'filter',
  Public = 'public',
}

export const EDashboardItemsType = Item.type; // для использования как значение
export type DashboardItemsTypeValue = ValueOf<typeof EDashboardItemsType>; // для использования как тип

export const enum EDashboardElementsType {
  Button = 'BUTTON',
  Text = 'TEXT',
  Image = 'IMAGE',
  Filter = 'FILTER',
}

export const enum EDashboardDevice {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Tablet = 'tablet',
}

export interface IAutoScroll {
  container: HTMLElement | null;
  margin: number;
  distance: number;
  interval: number;
  speed: number;
}

export interface IResizeOptions {
  autoScroll: IAutoScroll;
}

export interface IDraggbleOptions {
  autoScroll: IAutoScroll;
}

export type DashboardResponsiveMode =
  | EDashboardDevice.Desktop
  | EDashboardDevice.Mobile;

export interface DashboardBorder {
  color?: string;
  form: 'solid' | 'outlined' | 'transparent';
  border: string | null;
}

export interface IDashboardItem extends Item {
  minW?: number;
  minH?: number;
  subGridOpts:
    | (Omit<GridStackOptions, 'children'> & {
        children: IDashboardItem[];
        name: {
          textContent: string;
          delta: TFormattedText;
          alignment: TextVerticalAlignment;
        };
        settings: {
          color: string;
          opacity: number;
          border: DashboardBorder;
          isTitle: boolean;
        };
      })
    | null;
}

export type IDashboardPage = Page;

export interface DashboardElementData {
  id: string;
  type: EDashboardElementsType;
  settings: DashboardElementSettings;
}

export interface DashboardWidgetData {
  id: string;
  settings: WidgetLocalSettings;
}

export type IDashboardData = Omit<
  DashboardResponseDto,
  'visualAttributes' | 'modelId' | 'elements' | 'widgets'
> & {
  modelId?: string | null;
  visualAttributes: {
    formatting: {
      name: TFormattedText;
    };
  };
  elements: DashboardElementData[];
  widgets: DashboardWidgetData[];
};
export interface NestedObject {
  id: string;
  name: string;
  visualOptions: IVisualOptions;
}
export type DashboardDataExtended = Pick<
  DashboardResponseDto,
  'id' | 'name' | 'modelId' | 'creationTime' | 'ownerName'
> & {
  showcaseId: string;
  modificationTime: string;
  owner: string;
  project: NestedObject;
  workspace: NestedObject;
};

export type TCopyDashboard = {
  dashboardId: string;
  newName: string;
};

export type NewDashboard = Pick<
  IDashboardData,
  'modelId' | 'projectId' | 'name' | 'elements' | 'widgets'
> & {
  visualAttributes: {
    formatting?: {
      name?: TFormattedText;
    };
  };
};

export interface IDashboard extends IDashboardData {
  isModification: boolean;
  responsiveMode: EDashboardDevice;
}

export interface IGridLayoutComponent {
  resizeEvent: (
    eventName: 'resizestart' | 'resizemove' | 'resizeend',
    id: string | number,
    x: number,
    y: number,
    h: number,
    w: number
  ) => void;
}
export type TDashboardGridBreakpoints = 'lg' | 'md' | 'sm' | 'xs' | 'xxs';

export type IDashboardResponsiveLayouts = {
  [key in TDashboardGridBreakpoints]?: IDashboardItem[];
};

export type DashboardSchemaItem = Required<
  Omit<DashboardModelSchemaColumnDto, 'dataType' | 'type'>
> & {
  type: ETypeColumn;
  dataType: EDataType;
};

export type DashboardModelSchema = Required<
  Omit<DashboardModelSchemaDto, 'columns'>
> & {
  columns: DashboardSchemaItem[];
};

export enum EDashboardFilterContextType {
  Dashboard = 'DASHBOARD',
  Page = 'PAGE',
}

export enum EDashboardFilterComparison {
  Eq = 'eq',
  NotEq = 'notEq',
  StartWith = 'startWith',
  NotStartWith = 'notStartWith',
  Contains = 'contains',
  NotContains = 'notContains',
  IsEmpty = 'isEmpty',
  IsNotEmpty = 'isNotEmpty',
  Union = 'between',
  Less = 'smaller',
  LessThanEqual = 'smallerOrEq',
  Greater = 'greater',
  GreaterThanEqual = 'greaterOrEq',
  Includes = 'includes',
  NotIncludes = 'notIncludes',
  EndWith = 'endWith',
  NotEndWith = 'notEndWith',
  DateEq = 'dateEq',
  DateNotEq = 'dateNotEq',
  ExtUnion = 'extendedBetween',
}

export const enum EKeywords {
  Year = 'YEAR',
  Quarter = 'QUARTER',
  Month = 'MONTH',
  Today = 'TODAY',
  Tomorrow = 'TOMORROW',
  Yesterday = 'YESTERDAY',
  Date = 'DATE',
}
export interface DashboardFilterValuesExt {
  keyword: EKeywords;
  value?: string;
}

export type FilterCreateRequest = Omit<
  DashboardFilterRequestDto,
  'type' | 'comparison' | 'columnType' | 'columnDataType' | 'pageId' | 'values'
> & {
  type: EDashboardFilterContextType;
  columnType: ETypeColumn;
  columnDataType: EDataType;
  comparison: EDashboardFilterComparison;
  pageId?: string | null;
  values: DashboardFilterValuesExt[] | string[];
};

export type DashboardFilterFormData = Omit<
  FilterCreateRequest,
  'comparison'
> & {
  comparison: EDashboardFilterComparison | null;
};

export interface DashboardFilterData extends FilterCreateRequest {
  id: string;
  owner: string;
  creationTime: string;
  modificationTime: string;
}

export interface DashboardInfoPage {
  id: string;
  name: string;
  colsCount: number;
  rowsHeight: number;
  isVisible?: boolean;
}
export interface DashboardInfo {
  pages?: Page[];
  id?: string;
  tabId?: string | null;
  isModeView?: boolean;
  isFilterOpened: boolean;
}
export type DashboardFilterFormNewData = Pick<
  DashboardFilterData,
  | 'modelId'
  | 'modelName'
  | 'columnName'
  | 'columnId'
  | 'columnDataType'
  | 'columnType'
  | 'name'
  | 'isEnabled'
  | 'type'
  | 'isMultiple'
> & {
  dashboardId?: string;
};

export interface DashboardLocalFilterState {
  info: DashboardFilterData;
  values: unknown[] | null;
}

export interface IDashboardsStateItem {
  additionalFilters: Record<
    string, // widgetId
    {
      global: Record<
        string, // filterId
        DashboardLocalFilterState
      >;
      dynamic: Set<DynamicAdditionalFilter>;
    }
  >;
  filter: Record<string, Condition[]>;
  states: Set<EStateDashboard>;
}

export interface IDashboardsState {
  dashboards: Record<string, IDashboardsStateItem>;
}

export interface DashboardChangeTabArgs {
  widgetId: string;
  pageId: string;
}

export const modalTypes = {
  Filter: 'Filter',
  WidgetPicker: 'WidgetPicker',
  ChartInterpretation: 'ChartInterpretation',
  Copy: 'Copy',
  Right: 'Right',
  PreviewWidget: 'PreviewWidget',
} as const;

export type DashboardModalTypesValue = ValueOf<typeof modalTypes>;

export type DashboardGridItemDefaultSize = Record<
  EDashboardDevice,
  {
    w: number;
    h: number;
    minW?: number;
    minH?: number;
  }
>;

export type ActionArgs = {
  actionName: EActionNames | Action;
  payload?: boolean | { id: string; newIndex: number } | TextEditorChangeData;
};
