import { watch } from 'vue';

import { useBreadcrumbStore } from '@/store/bi-store/breadcrumb';

export function useBreadcrumbsWatch(callBack: () => Promise<void> | void) {
  const crumbsStore = useBreadcrumbStore();
  watch(
    () => crumbsStore.needRefresh,
    async isTrue => {
      if (isTrue) {
        await callBack();
        crumbsStore.needRefresh = false;
      }
    }
  );
}
