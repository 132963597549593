/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Filter = {
    comparison?: Filter.comparison;
    table?: string;
    database?: string;
    field?: string;
    modelId?: string;
    columnId?: string;
    formula?: string;
    aggregateFunction?: Filter.aggregateFunction;
    selectColumnId?: string;
};

export namespace Filter {

    export enum comparison {
        EQ = 'eq',
        NOT_EQ = 'notEq',
        GREATER = 'greater',
        GREATER_OR_EQ = 'greaterOrEq',
        SMALLER = 'smaller',
        SMALLER_OR_EQ = 'smallerOrEq',
        START_WITH = 'startWith',
        NOT_START_WITH = 'notStartWith',
        END_WITH = 'endWith',
        NOT_END_WITH = 'notEndWith',
        INCLUDES = 'includes',
        NOT_INCLUDES = 'notIncludes',
        BETWEEN = 'between',
        EXTENDED_BETWEEN = 'extendedBetween',
        CONTAINS = 'contains',
        NOT_CONTAINS = 'notContains',
        AND = 'and',
        OR = 'or',
        FORMULA = 'formula',
        IS_EMPTY = 'isEmpty',
        IS_NOT_EMPTY = 'isNotEmpty',
        IS_NULL = 'isNull',
        IS_NOT_NULL = 'isNotNull',
        DATE_EQ = 'dateEq',
        DATE_NOT_EQ = 'dateNotEq',
    }

    export enum aggregateFunction {
        SUM = 'SUM',
        AVG = 'AVG',
        COUNT = 'COUNT',
        UNIQ = 'UNIQ',
        MIN = 'MIN',
        MAX = 'MAX',
        NONE = 'NONE',
        AUTO = 'AUTO',
        MEDIAN = 'MEDIAN',
    }


}

