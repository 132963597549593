/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataProviderStructureViewDto } from './DataProviderStructureViewDto';

export type ModelViewResponseDto = {
    id: string;
    /**
     * Model name
     */
    name: string;
    /**
     * Model type
     */
    type: ModelViewResponseDto.type;
    /**
     * Project id
     */
    projectId: string;
    creationTime: string;
    ownerName?: string;
    structure?: DataProviderStructureViewDto;
    editable?: boolean;
};

export namespace ModelViewResponseDto {

    /**
     * Model type
     */
    export enum type {
        SHOWCASE = 'SHOWCASE',
        DICTIONARY = 'DICTIONARY',
        VIEW = 'VIEW',
        EXTENDED_VIEW = 'EXTENDED_VIEW',
        KAFKA = 'KAFKA',
        POSTGRES = 'POSTGRES',
        CLICKHOUSE = 'CLICKHOUSE',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
    }


}

