import { warn } from '~packages/bi-logger';

import { applyArraySearchParamsToURI } from '@/bi-core/helpers/api/applyArraySearchParamsToURI';
import type { SearchParams } from '@/types/api';
import getConstants from '@bi-core/constants/env';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
  RequestMethod,
} from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams = {
  className: 'WidgetApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
  needInit: isModeDevelopment,
};

export default class WidgetApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  async fetch(id: string | number, options: IRestOptions) {
    let apiServiceResponse;

    try {
      apiServiceResponse = await super.fetch(id, options);
      const widget = apiServiceResponse.response;
      if (!widget) warn('ERROR! Empty widget');

      return {
        errors: apiServiceResponse.errors,
        response: widget,
        status: apiServiceResponse.status,
      };
    } catch (error) {
      this._log('ERROR fetchAll: ' + error.message);
    }
  }

  filter(
    params: { projectId: string },
    data: SearchParams = {},
    options?: IRestOptions
  ) {
    const uri = applyArraySearchParamsToURI('/filter', params);
    return this.sendRequest(
      uri,
      null,
      {
        body: JSON.stringify(data),
        method: RequestMethod.POST,
      },
      { method: RequestMethod.POST, ...options }
    );
  }
}
