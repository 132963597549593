import { useAuthStore } from '@/store/bi-store/auth';
import { useShareStore } from '@/store/bi-store/share';
import type { IRouterContext } from '@/types/vue-router';
import { SHARE_ID } from '@bi-router/routes.json';

export function shared({ next, to }: IRouterContext): void {
  const authStore = useAuthStore();
  const shareStore = useShareStore();
  const shareId = to.params[SHARE_ID];

  if (authStore.isPublicRoute && typeof shareId === 'string' && shareId) {
    shareStore.sharedId = shareId;
    shareStore.isSharedPage = true;
  } else {
    shareStore.sharedId = null;
    shareStore.isSharedPage = false;
  }

  return next();
}
