/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AbstractModelRequestDto = {
    id?: string;
    /**
     * Model name
     */
    name: string;
    /**
     * Model type
     */
    type: AbstractModelRequestDto.type;
    /**
     * Project id
     */
    projectId: string;
};

export namespace AbstractModelRequestDto {

    /**
     * Model type
     */
    export enum type {
        SHOWCASE = 'SHOWCASE',
        DICTIONARY = 'DICTIONARY',
        VIEW = 'VIEW',
        EXTENDED_VIEW = 'EXTENDED_VIEW',
        KAFKA = 'KAFKA',
        POSTGRES = 'POSTGRES',
        CLICKHOUSE = 'CLICKHOUSE',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
    }


}

