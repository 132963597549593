import { useQuasar } from 'quasar';

export interface IPlatform {
  isMobile: boolean;
  isDesktop: boolean;
}

export function usePlatform(): IPlatform {
  const { platform } = useQuasar();

  return {
    isDesktop: !!platform.is.desktop,
    isMobile: !!platform.is.mobile,
  };
}
