import { IUser, isUser } from '@/types/user';

export type { IUser };
export { isUser };

const anonymous = 'Аноним';

export function getFullNameUser(user: IUser): string {
  if (!user) return anonymous;

  let firstName: string = user?.firstName || '';
  const lastName: string = user?.lastName || anonymous;

  if (typeof firstName === 'string' && firstName.length > 0) {
    firstName = `${firstName[0].toUpperCase()}.`;
  }

  return `${lastName} ${firstName}`;
}
