import { updateToken } from '~packages/keycloak';

import type {
  InterceptorRequest,
  InterceptorRequestParams,
} from '@/api/client';
import { useAuthStore } from '@/store/bi-store/auth';
import getConstants from '@bi-core/constants/env';

export default function authInterceptor(): InterceptorRequest {
  const { getVars, isModeDevelopment } = getConstants();
  const envVars = getVars();
  const authStore = useAuthStore();

  return async ({ options }: InterceptorRequestParams) => {
    if (!envVars.VITE_DISABLED_AUTHORIZATION && !authStore.isPublicRoute) {
      let token: string | null;

      if (isModeDevelopment || envVars.VITE_ACCESS_TOKEN) {
        token = envVars.VITE_ACCESS_TOKEN;
      } else {
        const result = await updateToken();
        token = result.token;
      }

      if (token) options.headers.Authorization = `Bearer ${token}`;
    }
    return options;
  };
}
