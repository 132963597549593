import { loadModules } from '@clearview/helpers';

let loadApiServices = () => {
  return {};
};

// try {
//   const context = require.context('', false);

//   loadApiServices = () =>
//     context
//       .keys()
//       .filter(filePath => filePath.endsWith('.ts'))
//       .reduce((modules, moduleIndexFile) => {
//         let module = context(moduleIndexFile);

//         if (module.default) {
//           module = module.default;
//         }

//         const apiServiceName = moduleIndexFile.slice(2, -3);

//         return module && apiServiceName !== 'index' && apiServiceName !== 'auto'
//           ? {
//             ...modules,
//             [apiServiceName]: module,
//           }
//           : modules;
//       }, {});
// } catch {

// }

try {
  loadApiServices = () => {
    const modules = import.meta.glob('./*.ts', { eager: true });
    return loadModules(modules);
  };
} catch {
  //
}

const apiServices = loadApiServices();

export { apiServices };
export default apiServices;
