import type { App } from 'vue';
import type { Router } from 'vue-router';

import getConstants from '@/bi-core/constants/env';
import * as Sentry from '@sentry/vue';

interface SentryOption {
  router?: Router;
}

export const initSentry = (app: App, { router }: SentryOption) => {
  const constants = getConstants();
  const { getVars: getEnvVars } = constants;

  const env = getEnvVars();

  Sentry.init({
    enabled: Boolean(env.VITE_SENTRY_ENABLED) || false,
    app,
    dsn: 'https://5046d2edee50f84d18e7fcb659110fe9@ivyduikin-dev.mkskom.ru/2',
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      Sentry.browserTracingIntegration({
        router,
        enableInp: true,
      }),
      // TODO нужно включить на server
      // Sentry.feedbackIntegration({
      //   // Additional SDK configuration goes in here, for example:
      //   colorScheme: 'system',
      //   isEmailRequired: true,
      //   isNameRequired: true,
      // }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: env.VITE_SENTRY_ENVIRONMENT,
    release: 'clearview@' + env.VITE_APP_VERSION,
    trackComponents: true,
    hooks: ['mount', 'update', 'unmount'],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/ivyduikin-dev.mkskom.ru\/api/],
    // Session Replay
    replaysSessionSampleRate: 1,
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    profilesSampleRate: 1.0,
  });
};
