import { error } from '~packages/bi-logger';

import { getVars } from '@bi-core/constants/env/envVars';
import { loadModules } from '@clearview/helpers';

import { generateApiServices, getApiServiceEntityId } from './..';
import modulesApiServices from './__modules';

// eslint-disable-next-line @typescript-eslint/no-empty-function
let loadApiServices = () => {};

try {
  loadApiServices = () => {
    const modules = import.meta.glob('./*.ts', { eager: true });
    return loadModules(modules);
  };
} catch (err) {
  error(err);
}

const apiSettings = JSON.parse(getVars()?.VITE_API_SETTINGS || '{}');
const apiServices = generateApiServices(
  apiSettings.entitiesModulesMap,
  apiSettings.entitiesInfo,
  modulesApiServices
);

const apiServicesImported = loadApiServices();
for (const apiServiceName in apiServicesImported) {
  apiServices[getApiServiceEntityId(apiServiceName, true)] = params =>
    new apiServicesImported[apiServiceName](params);
}

export { apiServices };
export default apiServicesImported;
